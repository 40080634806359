import {
  createContext,
  useContext,
  useCallback,
} from "react";

import {
  AlbumWithAssets,
  Asset,
  Collection,
  Workspace,
  uploadFile,
  Album,
  getAlbums,
  getWorkspace,
  createAlbum,
  fetchAssets,
  deleteAsset,
  Label,
  Tag,
  patchAlbum,
  updateAssetMetadata,
} from "./apiClient";

import { activeUploads, selectedAssetDictionary, UploadStatus } from "../atoms";
import { atom, atomFamily, selector, selectorFamily, useRecoilState, useRecoilValue, useRecoilValueLoadable } from "recoil";

export function useWorkspace(): [Workspace | null, boolean, boolean] {
  // TODO: "The" react way would be Suspense and ErrorBoundary..
  const loadable = useRecoilValueLoadable(workspaceAtom);
  switch (loadable.state) {
    case 'hasValue':
      return [loadable.contents, false, false];
    case 'loading':
      return [null, true, false];
    case 'hasError':
      return [null, false, true];
  }
}


export function useCollection(
  collectionId: string | undefined | null
): [Collection | null, boolean, boolean] {
  // TODO: "The" react way would be Suspense and ErrorBoundary..
  const loadable = useRecoilValueLoadable(collectionSelector(collectionId))
  switch (loadable.state) {
    case 'hasValue':
      return [loadable.contents, false, false];
    case 'loading':
      return [null, true, false];
    case 'hasError':
      return [null, false, true];
  }
}

export const CollectionContext = createContext<string | null>(null);
export const AlbumContext = createContext<string | null>(null);
export interface AssetAugmentation {
  link: string;
}

export interface AssetState {
  isSelected?: boolean;
}

export function useSelectedAssets() {
  const [selectedAssets, setSelectedAssets] = useRecoilState(
    selectedAssetDictionary
  );

  function toggleSelectAsset(assetId: string, currentState: boolean) {
    setSelectedAssets((prevState) => ({
      ...prevState,
      [assetId]: { isSelected: !currentState },
    }));
  }

  function getSelectedAssets() {
    return Object.keys(selectedAssets).filter(
      (id) => selectedAssets[id]?.isSelected
    );
  }
  function getAssetState(assetId: string) {
    if (selectedAssets[assetId]) {
      return selectedAssets[assetId];
    }
    return { isSelected: false };
  }
  return {
    selectedAssets,
    getSelectedAssets,
    getAssetState,
    toggleSelectAsset,
  };
}

export function useUploads(albumId: string, collectionId: string) {
  const [atomFiles, setAtomFiles] = useRecoilState(activeUploads);
  const { refreshAssets } = useAssetsHook({ albumId, collectionId });


  function handleFileSelect(evt: any) {
    const newFiles = [];
    for (const f of evt.target.files) {
      const newFileUpload = {
        file: f,
        albumId: albumId,
        collectionId: collectionId,
        state: UploadStatus.NOT_STARTED,
      };
      newFiles.push(newFileUpload);
    }
    setAtomFiles(atomFiles.concat(newFiles));
  }

  function removeFile(filename: string) {
    setAtomFiles(
      atomFiles.filter((fileUpload) => {
        return fileUpload.file.name !== filename;
      })
    );
  }

  const handleUpload = async (albumId: string, collectionId: string) => {
    atomFiles
      .filter(
        (fileUpload) =>
          fileUpload.albumId === albumId &&
          fileUpload.collectionId === collectionId
      )
      .forEach((fileUpload) => {
        uploadFile(fileUpload)
          .then((response) => {
            setAtomFiles((currentFiles) => {
              const fileIndex = currentFiles.indexOf(fileUpload);
              const newAtomFiles = [
                ...currentFiles.slice(0, fileIndex),
                { ...fileUpload, state: UploadStatus.SUCCESS },
                ...currentFiles.slice(fileIndex + 1),
              ];
              return newAtomFiles;
            });
          })
          .catch((error) => {
            console.error(error);
            setAtomFiles((currentFiles) => {
              const fileIndex = currentFiles.indexOf(fileUpload);
              const newAtomFiles = [
                ...currentFiles.slice(0, fileIndex),
                { ...fileUpload, state: UploadStatus.FAILURE },
                ...currentFiles.slice(fileIndex + 1),
              ];
              return newAtomFiles;
            });
          })
          .finally(async () => {
            refreshAssets();
          });
      });
  };

  return { atomFiles, handleFileSelect, handleUpload, removeFile };
}

export function useAlbumsHook(collectionId: string) {
  const [, setAlbums] = useRecoilState(albumsAtom(collectionId))

  const _refreshAlbums = async () => {
    const albums = await getAlbums(collectionId);

    setAlbums(albums);
  }

  const _createAlbum = async (name: string) => {
    await createAlbum(collectionId, name);
    _refreshAlbums();
  }

  return { createAlbum: _createAlbum, refreshAlbums: _refreshAlbums }
}

export function useAlbumHook(collectionId: string, albumId: string) {
  const { refreshAlbums } = useAlbumsHook(collectionId);
  const { refreshAssets } = useAssetsHook({ collectionId, albumId });

  const _patchAlbum = async (newValues: { labels: Label[]; tags: Tag[] }) => {
    patchAlbum(collectionId, albumId, newValues)
      .then(() => {
        refreshAlbums();
        refreshAssets();
      })
      .catch((err) => {
        console.error(err);
        refreshAlbums();
        refreshAssets();
      })
  }

  return { patchAlbum: _patchAlbum }
}

/*
  These atoms (workspace, albums and assets) correspond to our backend entities and should follow those definitions
  closely.
  Everything else we need (for convenience in the UI) should be derived selectors from those.
*/

export const workspaceAtom = atom<Workspace | null>({
  key: 'Workspace',
  default: selector<Workspace | null>({
    key: 'Workspace/Default',
    get: async () => {
      return await getWorkspace();
    },
  }),
});


export const albumsAtom = atomFamily<Album[], string>({
  key: 'AlbumsByCollection',
  default: selectorFamily<Album[], string>({
    key: 'AlbumsByCollection/Default',
    get: collectionId => () => {
      return getAlbums(collectionId);
    },
  }),
});


export const assetsAtom = atomFamily<Asset[], { collectionId: string, albumId: string }>({
  key: 'AssetsByAlbum',
  default: selectorFamily<Asset[], { collectionId: string, albumId: string }>({
    key: 'AssetsByAlbum/Default',
    get: ({ collectionId, albumId }) => () => {
      return fetchAssets(collectionId, albumId);
    },
  }),
});


export function useAssetsHook(params: { collectionId: string, albumId: string }) {
  const [, setAssets] = useRecoilState(assetsAtom(params))

  const _deleteAsset = async (assetId: string) => {
    await deleteAsset(params.collectionId, params.albumId, assetId);
    const albums = await fetchAssets(params.collectionId, params.albumId);

    setAssets(albums)
  }

  const _refreshAssets = async () => {
    const albums = await fetchAssets(params.collectionId, params.albumId);
    setAssets(albums)
  }

  return { deleteAsset: _deleteAsset, refreshAssets: _refreshAssets }
}

export function useAsset(params: { assetId: string, albumId?: string, collectionId?: string }) {
  const ctxAlbumId = useContext(AlbumContext);
  const ctxCollectionId = useContext(CollectionContext);

  const assetId = params.assetId;
  const collectionId = params.collectionId || ctxCollectionId;
  const albumId = params.albumId || ctxAlbumId;

  const asset = useRecoilValue(
    assetSelector({
      collectionId: collectionId || '',
      albumId: albumId || '',
      assetId: params.assetId,
    })
  );

  const [, setAssets] = useRecoilState(assetsAtom({
      collectionId: collectionId || '',
      albumId: albumId || ''
  }))

  const _deleteAsset = useCallback(async () => {
    if(!collectionId || !albumId) {
      return
    }
    await deleteAsset(collectionId, albumId, assetId);
    const albums = await fetchAssets(collectionId, albumId);
    setAssets(albums)
  }, [collectionId, albumId, assetId, setAssets]);

  const _updateAssetMetadata = useCallback(async (metadata) => {
    if(!collectionId || !albumId) {
      return
    }
    await updateAssetMetadata(metadata, collectionId, albumId, assetId);
    const albums = await fetchAssets(collectionId, albumId);
    setAssets(albums)
  }, [collectionId, albumId, assetId, setAssets])

  return {
    asset,
    deleteAsset: _deleteAsset,
    updateAssetMetadata: _updateAssetMetadata
  }
}


export const albumWithAssetsSelector = selectorFamily<AlbumWithAssets, { collectionId: string, albumId: string }>({
  key: 'AlbumWithAssets',
  get: ({ collectionId, albumId }) => ({ get }) => {
    const albums = get(albumsAtom(collectionId));
    const assets = get(assetsAtom({ collectionId, albumId }));
    let album = albums.filter(a => a.id === albumId)[0];

    return {
      ...album,
      assets: assets,
    }
  },
});


export const assetSelector = selectorFamily<Asset | null, { collectionId: string, albumId: string, assetId: string }>({
  key: 'SingleAssetSelector',
  get: ({ collectionId, albumId, assetId }) => ({ get }) => {
    const assets = get(assetsAtom({ collectionId, albumId }));
    return assets.filter(a => a.id === assetId)[0];
  },
});


export const collectionSelector = selectorFamily<Collection | null, string | null | undefined>({
  key: 'Collection',
  get: (collectionId) => async ({ get }) => {
    if (!collectionId) {
      return null;
    }
    const albums = get(albumsAtom(collectionId))

    return {
      name: collectionId.charAt(0).toUpperCase() + collectionId.slice(1),
      id: collectionId,
      albums,
    }
  },
});
