import tw, { styled } from "twin.macro";
import { useState, useCallback, useRef, useEffect } from "react";

import { Tag, Label } from "../../clients/apiClient";
import { Icon } from "../generic";
import { BaseProps } from "../generic/BaseProps";

export interface LabelItemProps {
  isHighlight?: boolean;
  onDelete?: () => void;
}

export function LabelItem(props: BaseProps & LabelItemProps) {
  return <li
    className={props.isHighlight ? "bg-highlight-light" : ""}
    tw="lbl cursor-default inline-block">{props.children}
    {props.onDelete ? <Icon tw="cursor-pointer absolute right-1.5" t="close" onClick={props.onDelete}/> : null}
  </li>
}

export interface TagItemProps {
  isHighlight?: boolean;
  tag?: Tag;
  newValuesFn?: Function;
}

export function TagItem({
  onClick,
  isHighlight,
  tag,
  newValuesFn = () => {},
}: BaseProps & TagItemProps): JSX.Element {
  const [key, setKey] = useState(tag?.key || null);
  const [value, setValue] = useState(tag?.value || null);
  const [showValue, setShowValue] = useState<boolean>(false);

  const inputRef = useRef<HTMLInputElement>(null);

  function handleChangeKey(event: any) {
    if (event.keyCode === 13) {
      const newKey = event.target.value;
      setKey(newKey);
      setShowValue(true);
    }
    if (event.target.value.includes(":")) {
      const newKey = event.target.value.replace(":", "");
      setKey(newKey);
      setShowValue(true);
    }
  }

  useEffect(() => {
    inputRef.current?.focus();
  }, [showValue]);

  function handleChangeValue(event: any) {
    if (event.keyCode === 13) {
      const newValue = event.target.value;
      setValue(newValue);
      saveNewAttribute(key, newValue);
    }
  }

  function saveNewAttribute(key?: string | null, value?: string | null) {
    if (key && value) {
      newValuesFn({ key, value });
    }
  }

  return (
    <li
      tw="cursor-pointer inline-block border border-grey-light"
      onClick={onClick}
    >
      {key ? (
        <span
          className={isHighlight ? "bg-highlight-lighter" : ""}
          tw="tag-key text-grey-dark"
        >
          {key}
          {": "}
        </span>
      ) : (
        <input
          tw="tag-key text-grey-dark"
          placeholder="Enter New Key"
          onKeyUp={handleChangeKey}
        />
      )}
      {value ? (
        <span className={isHighlight ? "bg-highlight-light" : ""} tw="tag-val">
          {value}
        </span>
      ) : key ? (
        <input
          tw="tag-key text-grey-dark"
          ref={inputRef}
          placeholder="Enter your value"
          onKeyUp={handleChangeValue}
        />
      ) : null}
    </li>
  );
}

export interface ItemSelection {
  item: Tag | Label;
  index: number;
  type: "tag" | "label";
}

export interface ItemGroupProps {
  tags?: Tag[];
  labels?: Label[];
  onSelect?: (selection: ItemSelection) => void;
}

export const ItemGroupContainer = styled.ul(({editMode}: {editMode?: boolean}) => [
  tw`flex flex-wrap gap-2 transition-all border border-transparent`,
  editMode && tw`border-grey m-[-6px] p-[6px]`,
]);

export function ItemGroup({
  tags,
  labels,
  children,
  onSelect,
}: BaseProps & ItemGroupProps) {
  const [, /*selectState*/ setSelectState] = useState({});
  const handleSelect = useCallback(
    (type: "tag" | "label", index: number, item: Tag | Label) => {
      setSelectState({ type, index, item });
      if (onSelect) {
        onSelect({ type, index, item });
      }
    },
    [onSelect]
  );

  return (
    <ItemGroupContainer>
      {tags &&
        tags.map((t, idx) => (
          <TagItem tag={t} onClick={() => handleSelect("tag", idx, t)} />
        ))}
      {labels &&
        labels.map((l, idx) => (
          <LabelItem onClick={() => handleSelect("label", idx, l)}>
            {l}
          </LabelItem>
        ))}
      {children}
    </ItemGroupContainer>
  );
}

export interface LabelsProps {
  labels?: Label[];
  onCreate?: (value: string) => void;
  onDelete?: (value: string) => void;
}

export function Labels({ labels, onDelete, onCreate, className }: BaseProps & LabelsProps): JSX.Element {
  const [editMode, setEditMode] = useState(false);


  useEffect(() => {
    function handleClick() {
      setEditMode(false)
    }

    document.addEventListener('click', handleClick)
    return () => {
      document.removeEventListener('click', handleClick)
    }
  }, [editMode, setEditMode])

  const handleInput = useCallback((evt) => {
    if(evt.keyCode === 13) {
      evt.preventDefault();
      onCreate && onCreate(evt.target.value);
      // FIXME empty input on success
    }
  }, [onCreate])

  const handleEnterEditMode = useCallback((evt) => {
      evt.stopPropagation()
      setEditMode(true)
  }, [setEditMode])

  return (
    <div className={className || undefined}>
      <ItemGroupContainer tw="relative" editMode={editMode} onClick={handleEnterEditMode}
        className={editMode ? "editMode" : ""}>
        {labels?.map(l => (
          <LabelItem
              key={l}
              onDelete={editMode && onDelete ? () => onDelete(l) : undefined}
            >{l} </LabelItem>
        ))}
        {onCreate ? (
          <input
            tw="lbl bg-white hover:bg-grey-lightest active:bg-grey-lightest w-32 leading-[1.25rem]"
            placeholder="+ Add label"
            onKeyUp={handleInput}
          />
        ): null}
        {onDelete && !editMode ? (
          <Icon t='edit' tw='text-grey-light text-2xl absolute bottom-0 right-0 cursor-pointer' />
        ): null}
      </ItemGroupContainer>
    </div>
  )
}
