import { useUploads } from "../../clients/apiHooks";

interface uploadListProps {
  current: boolean;
  albumId: string;
  collectionId: string;
}

export function UploadList({
  current,
  albumId,
  collectionId,
}: uploadListProps) {
  const { atomFiles, removeFile } = useUploads(albumId, collectionId);
  const displayFiles = atomFiles.filter(f => {
    if (current) {
      return f.albumId === albumId && f.collectionId === collectionId
    } else {
      return f.albumId !== albumId || f.collectionId !== collectionId
    }
  });

  return (
    <div>
      {displayFiles.map(f => (
        <div key={f.file.name} tw="flex flex-row">
          <div key={f.file.name + "_display"}>
            {f.file.name} {f.file.size} ({f.file.type}) ({f.state}){" "}
            {f.collectionId}/{f.albumId}
          </div>
          <div
            id={f.file.name}
            key={f.file.name + "_remove"}
            onClick={() => removeFile(f.file.name)}
          >
            [RemoveFile]
          </div>
        </div>
      ))}
    </div>
  );
}
