import "twin.macro";

import { Link } from "react-router-dom";

import { Album } from "../../clients/apiClient";

export interface AlbumListProps {
  collectionId: string;
  albums: Album[];
  filter?: string;
}

// const Foo = tw.div`btn text-5xl font-bold`; //

function AlbumList({ albums, collectionId }: AlbumListProps): JSX.Element {
  return (
    <div>
      <span tw="label">Albums</span>
      <Link to={`/collection/${collectionId}/new-album`}>
        <button tw="my-2">+ New Album</button>
      </Link>
      <div tw="p-2 border border-grey text-grey-dark mb-2">
        filter / sort / group by ...
      </div>
      {[...albums]
        .sort((a, b) => a.id.localeCompare(b.id))
        .map((a) => (
          <Link key={a.id} to={`/collection/${collectionId}/a/${a.id}`}>
            <h2 tw="leading-none">{a.name}&nbsp;&gt;&gt;</h2>
          </Link>
        ))}
    </div>
  );
}

export default AlbumList;
