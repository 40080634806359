import "twin.macro";

import { useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useCollection } from "../../clients/apiHooks";

import AlbumList from "./AlbumList";
import ResourceInput from "../generic/ResourceInput";
import ErrorBoundary from "../generic/ErrorBoundary";

function CollectionView(): JSX.Element | null {
  const { id } = useParams<{ id?: string }>();
  const [collection /*, loading, err*/] = useCollection(id);
  const [isEditName, setEditName] = useState(false);

  const handleSubmit = useCallback((val: string) => {
    setEditName(false);
  }, []);

  if (!id) {
    return null;
  }

  return (
    <ErrorBoundary>
    <div>
      {collection && (
        <div>
          <span tw="label">Collection</span>
          {isEditName ? (
            <ResourceInput
              tw="mb-4"
              initialValue={collection.name}
              inputClass="h1 w-full"
              onSubmit={handleSubmit}
              submitOnBlur
              cta="save"
            />
          ) : (
            <h1 tw="mb-4" onClick={() => setEditName(true)}>
              {collection.name}
            </h1>
          )}
          <AlbumList collectionId={id} albums={collection.albums} />
        </div>
      )}
    </div>
    </ErrorBoundary>
  );
}

export default CollectionView;
