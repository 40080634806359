import "twin.macro";

import { useState, useCallback } from "react";

export interface CreateResourceInputProps {
  onSubmit: (val: string) => void;
  onChange?: (val: string) => void;
  submitOnBlur?: boolean;
  submitDisabled?: boolean;

  placeholder?: string;
  initialValue?: string;

  error?: string | JSX.Element | JSX.Element[] | null;
  cta?: string;
  className?: string;
  inputClass?: string;
}

function ResourceInput(props: CreateResourceInputProps): JSX.Element {
  const [value, setValue] = useState(props.initialValue || "");

  const { onSubmit, onChange, submitOnBlur, submitDisabled } = props;

  const handleSubmit = useCallback(
    (evt: React.FormEvent) => {
      evt.preventDefault();
      if (!submitDisabled) {
        onSubmit(value);
      }
    },
    [onSubmit, submitDisabled, value]
  );

  const handleChange = useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      setValue(evt.target.value);
      if (onChange) {
        onChange(evt.target.value);
      }
    },
    [onChange]
  );

  const handleBlur = useCallback(
    (evt: React.FormEvent) => {
      if (submitOnBlur && !submitDisabled) {
        onSubmit(value);
      }
    },
    [submitOnBlur, onSubmit, submitDisabled, value]
  );

  return (
    <form className={props.className} onSubmit={handleSubmit}>
      <input
        name="name"
        type="text"
        value={value}
        className={props.inputClass}
        autoFocus
        placeholder={props.placeholder}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      {props.error ? (
        <div tw="text-warn-dark text-xl">{props.error}</div>
      ) : null}
      <input
        type="submit"
        name="submit"
        value={props.cta || "create"}
        tw="btn action text-base"
        disabled={!!props.submitDisabled || !value}
      />
    </form>
  );
}

export default ResourceInput;
