import "twin.macro";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";

import CollectionView from "./components/collection/CollectionView";
import NewCollectionView from "./components/collection/NewCollectionView";
import AlbumView from "./components/album/AlbumView";
import NewAlbumView from "./components/album/NewAlbumView";
import TopLevelNavigation from "./components/navigation/TopLevelNavigation";
import LandingView from "./components/LandingView";
import LoginView from "./components/LoginView";
import { RecoilRoot } from "recoil";
import { Suspense } from "react";

function App() {
  return (
    <RecoilRoot>
      <div className="App" tw="px-4 py-12 max-w-screen-fhd mx-auto">
        <Router>
          <Routes>
            <Route path="/" element={<LandingView />}/>
          </Routes>
          <header className="App-header" tw="flex flex-row min-w-min">
            <TopLevelNavigation tw="flex-shrink-0 w-64 mr-4" />
            <div
              id="divOne"
              tw="flex-1 max-w-screen-lg mx-auto min-w-screen sm:min-w-0 pl-4 sm:pl-0"
            >
              <Routes>
                <Route path="/login" element={<LoginView />} />
                <Route
                  path="/new-collection"
                  element={
                    <PrivateRoute>
                      <NewCollectionView />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/collection/:collectionId/new-album"
                  element={
                    <PrivateRoute>
                      <NewAlbumView />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/collection/:id"
                  element={
                    <PrivateRoute>
                      <CollectionView />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/collection/:collectionId/a/:id"
                  element={
                    <PrivateRoute>
                      <Suspense fallback={<div/>}>
                        <AlbumView />
                      </Suspense>
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/collection/:collectionId/a/:id/s/:assetId"
                  element={
                    <PrivateRoute>
                      <Suspense fallback={<div/>}>
                        <AlbumView />
                      </Suspense>
                    </PrivateRoute>
                  }
                />
              </Routes>
            </div>
          </header>
        </Router>
      </div>
    </RecoilRoot>
  );
}

function PrivateRoute({ children }: any) {
  const token = localStorage.getItem("token");
  return token ? children : <Navigate to="/login" />;
}

export default App;
