import "twin.macro";

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useWorkspace } from "../../clients/apiHooks";
import { createCollection } from "../../clients/apiClient";

import ResourceInput from "../generic/ResourceInput";

function CollectionExistsErr(): JSX.Element {
  return (
    <div>
      A <b>collection</b> with that <b>name already exists</b> in your
      workspace.
    </div>
  );
}

function NewCollectionView(): JSX.Element {
  const navigate = useNavigate();
  const [duplicationErr, setDuplicationErr] = useState(false);
  const [createLoading, setCreateLoading] = useState(false);
  const [collections /*, loading, err*/] = useWorkspace();

  function changeHandler(val: string) {
    setDuplicationErr(
      !!collections && !!collections.filter((c) => c.name === val.trim()).length
    );
  }

  function submitHandler(val: string) {
    setCreateLoading(true);
    createCollection(val.trim())
      .then((c) => {
        setCreateLoading(false);
        navigate(`/collection/${c.id}`, { replace: true });
      })
      .catch((err) => {
        console.error(err);
        setCreateLoading(false);
      });
  }

  return (
    <div>
      <span tw="label">New Collection</span>
      <ResourceInput
        tw="h1"
        placeholder="collection name"
        onChange={changeHandler}
        onSubmit={submitHandler}
        submitDisabled={!!(duplicationErr || createLoading)}
        error={duplicationErr ? <CollectionExistsErr /> : null}
      />
      This will create a new collection in your workspace. You can edit settings
      and choose how to share it after it's creation.
    </div>
  );
}

export default NewCollectionView;
