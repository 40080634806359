import { Route, Routes } from "react-router-dom";

import CollectionList from "./CollectionList";
import AlbumList from "./AlbumList";
import { Suspense } from "react";

function TopLevelNavigation({
  className,
}: {
  className?: string;
}): JSX.Element {
  return (
    <div className={className}>
      <Routes>
        <Route
          path="/collection/:collectionId/*"
          element={<Suspense fallback={<div />}><AlbumList /></Suspense>}
        />
        <Route path="/collection/:collectionId" element={<CollectionList />} />
        <Route path="/collection/" element={<CollectionList />} />
      </Routes>
    </div>
  );
}

export default TopLevelNavigation;
