import React from "react";

export default class ErrorBoundary extends React.Component<{}, { error: Error | null }> {
  constructor(props: {}) {
    super(props)
    this.state = { error: null }
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.error(errorInfo)
    this.setState({ error })
  }

  render() {
    if(this.state.error) {
      return <pre>this.state.error</pre>
    } else {
      return this.props.children
    }
  }
}
