import "twin.macro";

function NewAlbumView(): JSX.Element {
  return (
    <div>
      <span tw="label">New Album</span>
      This will create a new album in the collection
      &lt;COLLECTION_NAME_HERE&gt;. You can add tags, labels and assets after
      it's creation.
    </div>
  );
}

export default NewAlbumView;
