import { FileUpload, UploadStatus } from "../atoms";
import { AssetAugmentation } from "./apiHooks";

declare var __API_ROOT__: string;

export function apiUrl(path: string, prefix: string = "/api") {
  const apiRoot = (typeof __API_ROOT__ !== "undefined" && __API_ROOT__) || "";
  const normPath = `${path.startsWith("/") ? "" : "/"}${path}`;
  return `${apiRoot}${prefix}${normPath}`;
}

async function apiFetch(path: string, config?: RequestInit) {
  const token = localStorage.getItem("token");
  if (!token) {
    //TODO: something more intelligent
    return;
  }
  return fetch(apiUrl(path), {
    ...config,
    headers: { token, ...config?.headers },
  }).then((response) => {
    if (response.status >= 200 && response.status < 300) {
      return response.json();
    } else {
      throw new Error(`Something went wrong when calling ${path}`);
    }
  })
}

export async function uploadFile(fileUpload: FileUpload) {
  if (fileUpload.state !== UploadStatus.NOT_STARTED) {
    return; //we already tried to upload this before, skip.
  }
  const formData = new FormData();
  formData.append("upload", fileUpload.file);

  return apiFetch(
    `/collections/${fileUpload.collectionId}/albums/${fileUpload.albumId}/assets`,
    {
      method: "POST",
      body: formData,
    }
  );
}

export interface CollectionBase {
  name: string;
  id: string;
}

export type Workspace = CollectionBase[];

export async function getWorkspace(): Promise<Workspace> {
  return (await apiFetch(`/collections`)).map((d: string) => ({
    name: d,
    id: d,
  }));
}

export interface Album {
  name: string;
  id: string;
  collectionId: string;
  labels: Label[];
  tags: Tag[];
}

export interface Collection {
  name: string;
  id: string;
  albums: Album[];
}

export async function getAlbums(collectionId: string): Promise<Album[]> {
  const albums = (await apiFetch(`/collections/${collectionId}`)).map(
    (a: { name: string; id: string }) => ({
      name: a.name,
      id: a.id,
      collectionId,
    })
  );

  return albums;
}

export async function createAlbum(
  collectionId: string,
  name: string
): Promise<Album> {
  const newPayload = { name };
  return apiFetch(`/collections/${collectionId}/albums`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    method: "POST",
    body: JSON.stringify(newPayload),
  });
}

export interface Asset {
  id: string;
  name: string;
  collectionId: string;
  albumId: string;
  path: string;
  download_path: string;
  url: string;
  labels: Label[];
  tags: Tag[];
  album_labels?: Label[];
  album_tags?: Tag[];
}

export interface Tag {
  key: string;
  value: string | number;
}

export type Label = string;

export interface AlbumWithAssets {
  id: string;
  name: string;
  collectionId: string;
  assets: Asset[];
  labels: Label[];
  tags: Tag[];
}

export async function fetchAssets(
  collectionId: string,
  albumId: string
): Promise<(Asset & AssetAugmentation)[]> {
  const assetResponse = await apiFetch(
    `/collections/${collectionId}/assets?album=${albumId}`
  );

  return assetResponse.assets.map((asset: any) => ({
    ...asset,
    collectionId,
    albumId,
    url: apiUrl(asset.path, ""),
    link: `/collection/${collectionId}/a/${albumId}/s/${asset.id}`,
    tags: asset.tags.map((t: [string, string]) => ({
      key: t[0],
      value: t[1],
    })),
  }));
}

export async function updateAssetMetadata(
  newValues: { labels?: Label[]; tags?: Tag[] },
  collectionId: string,
  albumId: string,
  assetId: string
) {
  const newTags = newValues.tags?.map(t => [t.key, t.value]);

  const newPayload = { labels: newValues.labels, tags: newTags };
  return apiFetch(
    `/collections/${collectionId}/albums/${albumId}/assets/${assetId}/metadata`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(newPayload),
    }
  );
}

export async function deleteAsset(
  collectionId: string,
  albumId: string,
  assetId: string
): Promise<null> {
  await apiFetch(
    `/collections/${collectionId}/albums/${albumId}/assets/${assetId}`,
    { method: "DELETE" }
  );

  return null;
}

export async function createCollection(name: string): Promise<CollectionBase> {
  return {
    id: name,
    name,
  };
}

export async function patchAlbum(
  collectionId: string,
  albumId: string,
  newValues: { labels: Label[]; tags: Tag[] }
) {
  let newTags = newValues.tags.map(({ key, value }) => [key, value]);
  const newPayload = { labels: newValues.labels, tags: newTags };
  return apiFetch(`/collections/${collectionId}/albums/${albumId}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    method: "PATCH",
    body: JSON.stringify(newPayload),
  });
}
