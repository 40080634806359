import "twin.macro";

import React, { useContext } from "react";
import { Link } from "react-router-dom";

import { LazyLoadImage } from "react-lazy-load-image-component";

import {
  AlbumContext,
  CollectionContext,
  useSelectedAssets,
} from "../../clients/apiHooks";
import { Asset } from "../../clients/apiClient";

export interface AssetTileProps {
  asset: Asset;
}

function AssetTile(props: AssetTileProps): JSX.Element | null {
  const asset = props.asset;

  const collectionId = useContext(CollectionContext);
  const albumId = useContext(AlbumContext);
  const { getAssetState } = useSelectedAssets();
  const assetState = getAssetState(asset.id);

  const link = `/collection/${collectionId}/a/${albumId}/s/${asset.id}`;

  if (!asset?.url) {
    return null;
  }

  if (asset.url.match(/(\.jpg|\.jpeg|\.png|\.pdf)$/i)) {
    return (
      <Link to={link} tw="min-h-40">
        <LazyLoadImage src={`${asset.url}?w=350&h=350`} alt={asset.url} />
        <div>{assetState.isSelected ? "Selected" : "Not selected"}</div>
      </Link>
    );
  }

  return (
    <a tw="min-h-40" href={asset.url}>
      {asset.url}
    </a>
  );
}

export default React.memo(AssetTile);
