import tw, { styled, css } from "twin.macro";
import { useCallback, useEffect, memo } from "react";
import { useNavigate } from "react-router-dom";

import { AlbumWithAssets } from "../../clients/apiClient";
import {
  useAsset,
  useSelectedAssets,
} from "../../clients/apiHooks";

import { TagItem, ItemGroupContainer, Labels } from "../tags/Items";

import { Icon } from "../generic";
import ErrorBoundary from "../generic/ErrorBoundary";
import { DocumentView } from "./DocumentView";

const ActionButton = styled.a([
  tw`font-bold px-8 py-4 inline-block rounded-full`,
  css`
    cursor: pointer;
    transition: all 0.3s;
    &:hover {
      background-color: #eee;
    }
  `,
]);

export interface AssetDetailsProps {
  id: string;
  album?: AlbumWithAssets | null;
  className?: string;
  onSelect: () => void;
  onClose: () => void;
}

function AssetDetails(props: AssetDetailsProps): JSX.Element {
  const { toggleSelectAsset, getAssetState } = useSelectedAssets();
  const assetState = getAssetState(props.id);
  let pdfUrl: string | boolean = false;

  const { asset, deleteAsset, updateAssetMetadata } = useAsset({ assetId: props.id });

  const handleDelete = () => deleteAsset().then(props.onClose);

  const navigate = useNavigate();
  const handleKeyPress = useCallback(
    (event) => {
      const album = props.album;
      if (album?.assets?.length) {
        const currIdx = album?.assets?.findIndex(
          (ast: any) => ast.id === props.id
        );
        const prevIdx = currIdx === 0 ? album.assets.length - 1 : currIdx - 1;
        const nextIdx = currIdx === album.assets.length - 1 ? 0 : currIdx + 1;
        const prev = album.assets[prevIdx];
        const next = album.assets[nextIdx];
        event.key === "ArrowRight" &&
          navigate(
            `/collection/${album.collectionId}/a/${album.id}/s/${next.id}`
          );
        event.key === "ArrowLeft" &&
          navigate(
            `/collection/${album.collectionId}/a/${album.id}/s/${prev.id}`
          );
      }
    },
    [props, navigate]
  );

  useEffect(() => {
    // FIXME this is also triggered when moving a cursor inside an input...
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);

  useEffect(() => {
    if (asset) {
      document.title = "ExRepo - " + asset.id;
    }
  });

  if (!asset) {
    return <div />;
  }

  if (asset.url.match(/(\.pdf)$/i)) {
    pdfUrl = asset.url.replace("/i/", "/o/");
  }

  function handleSelect() {
    toggleSelectAsset(props.id, assetState.isSelected);
    props.onSelect();
  }

  function handleNewLabel(newValue: string) {
    if (asset && newValue) {
      updateAssetMetadata({ labels: [...asset.labels, newValue] });
    }
  }
  function handleDeleteLabel(label: string) {
    if (asset && label) {
      updateAssetMetadata({ labels: asset.labels.filter(l => l !== label) });
    }
  }

  function handleNewTag({ key, value }: { key: string; value: string }) {
    if (!asset) {
      return;
    }
    const dataPayload = {
      tags: [...asset.tags, { key, value }],
    };
      updateAssetMetadata(dataPayload);
  }

  return (
    <ErrorBoundary>
    <div className={props.className} tw="flex h-full">
      <div tw="flex-grow bg-grey-lightest bg-opacity-95 relative justify-center items-center flex">
        {pdfUrl ? (
          <DocumentView fullUrl={pdfUrl} />
        ) : (
          <img
            src={`/i/${asset.collectionId}/${asset.albumId}/${asset.id}/${asset.id}?w=1000&h=1000`}
            alt={asset.id}
            tw="max-h-screen absolute top-1/2 left-1/2 transform translate-x--1/2 translate-y--1/2"
          />
        )}
      </div>
      <div tw="flex-none hidden md:block w-1/2 xl:w-1/3 bg-white px-12 py-24 space-y-8 overflow-auto">
        <div>
          <span tw="label">Name</span>
          <h2 tw="break-all"> {asset.name} </h2>
        </div>
        <div>
          <span tw="label">
            <Icon t="tag" /> Labels
          </span>
          <Labels labels={asset.labels} onCreate={handleNewLabel} onDelete={handleDeleteLabel}/>
          {asset.album_labels?.length ? (
            <div>
              <span tw="label font-normal mt-2">
                Labels inherited from album
              </span>
              <div>
                {" "}
                {asset.album_labels.map((l) => (
                  <span> {l} </span>
                ))}{" "}
              </div>
            </div>
          ) : null}
        </div>

        <div>
          <span tw="label">
            <Icon t="list" /> Attributes
          </span>
          <ItemGroupContainer>
            {asset.tags.map((t) => (
              <TagItem key={t.key + t.value} tag={t} />
            ))}
            <TagItem newValuesFn={handleNewTag} />
          </ItemGroupContainer>
          {asset.album_tags?.length ? (
            <div>
              <span tw="label font-normal mt-2">Tags inherited from album</span>
              <div>
                {" "}
                {asset.album_tags.map((t) => (
                  <span>
                    {t.key}: {t.value}
                  </span>
                ))}{" "}
              </div>
            </div>
          ) : null}
        </div>

        {false && (
          <div>
            <span tw="label">
              <Icon t="link-alt" /> Shares
            </span>
            <div> [website] </div>
          </div>
        )}

        <div tw="ml--8 flex flex-col">
          <div>
            <ActionButton tw="text-black" href={asset.download_path} download>
              <Icon t="download" /> Download
            </ActionButton>
          </div>
          <div>
            <ActionButton tw="text-danger" onClick={handleDelete}>
              <Icon t="delete" /> Delete
            </ActionButton>
          </div>
        </div>
        <div tw="space-x-4">
          <button onClick={handleSelect}>
            {assetState.isSelected ? "deselect" : "select"}
          </button>
        </div>
      </div>
    </div>
    </ErrorBoundary>
  );
}

export default memo(AssetDetails);
