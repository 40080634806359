import "twin.macro";

import { Link, useParams } from "react-router-dom";
import { albumsAtom, useCollection, useAlbumsHook } from "../../clients/apiHooks";

import AlbumListLink from "./AlbumListLink";
import { useRecoilValue } from "recoil";

export interface AlbumListProps {
  className?: string;
}

function AlbumList({ className }: AlbumListProps): JSX.Element {
  const { collectionId } = useParams<{ collectionId: string }>();
  const [collection, loading, err] = useCollection(collectionId);

  const albums = useRecoilValue(albumsAtom(collectionId!));

  const { createAlbum } = useAlbumsHook(collectionId!);

  const onKeyUp = (e: any) => {
    if (e.keyCode === 13) {
      createAlbum(e.target.value);
      e.target.value = "";
    }
  };

  return (
    <div className={className} tw="space-y-12">
      <div>
        <div tw="mb-4">
          <Link to={`/collection/${collectionId}`}>
            <span tw="label">&lt;&lt;&nbsp;All Collections</span>
          </Link>
        </div>

        <Link to={`/collection/${collectionId}`}>
          <span tw="label">Collection</span>
          <h2>
            <span tw="hover:underline">{collection && collection.name}</span>
          </h2>
        </Link>
      </div>

      <div>
        <span tw="label">Albums</span>
        {loading && <div>loading albums...</div>}
        {err && (
          <div>sorry, something went wrong fetching the collection...</div>
        )}
        <input
          tw="mt-2 lbl bg-grey-lightest w-32"
          placeholder="+ New Album"
          onKeyUp={onKeyUp}>
        </input>
        <div tw="divide-y divide-solid max-h-screen overflow-y-auto">
          {
            [...albums]
              .sort((a, b) => a.id.localeCompare(b.id))
              .map((a) => <AlbumListLink key={a.id} album={a} tw="py-2" />)}
        </div>
      </div>
    </div>
  );
}

export default AlbumList;
