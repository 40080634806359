import "twin.macro";
import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

import {
  CollectionContext,
  AlbumContext,
  useSelectedAssets,
  albumWithAssetsSelector,
} from "../../clients/apiHooks";

import TagsLabels from "../tags/TagsLabels";
import AssetTile from "./AssetTile";
import AssetDetails from "./AssetDetails";
import Uploads from "./Uploads";
import { Modal } from "../generic";
import ErrorBoundary from "../generic/ErrorBoundary";
import { UploadList } from "./UploadList";
import { useRecoilValue } from "recoil";

function AlbumView() {
  const { assetId, collectionId, id } = useParams<{
    id: string;
    collectionId: string;
    assetId: string;
  }>();
  const [showUpload /*, setShowUpload*/] = useState(false);
  const { getSelectedAssets } = useSelectedAssets();
  const selectedAssets = getSelectedAssets();

  const album = useRecoilValue(albumWithAssetsSelector({collectionId:collectionId!, albumId: id!}))
  const assets = album?.assets || [];

  const navigate = useNavigate();
  const handleCloseAssetDetails = () => {
    navigate(`/collection/${collectionId}/a/${id}`);
  };
  if (!album) {
    return null;
  }
  return (
    <ErrorBoundary>
    <CollectionContext.Provider value={collectionId ?? null}>
      {selectedAssets.length ? (
        <div>{selectedAssets.length} selected</div>
      ) : null}
      <AlbumContext.Provider value={id ?? null}>
        {showUpload && (
          <Modal>
            <Uploads albumId={album?.id} collectionId={album?.collectionId} />
          </Modal>
        )}
        <div tw="space-y-12">
          <div id="headerDiv" tw="flex flex-row justify-between">
            <div>
              <span tw="label">Album</span>
              <h1> {album?.name || id} </h1>
            </div>
            <div>
              <UploadList
                current={false}
                albumId={album?.id}
                collectionId={album?.collectionId}
              />
            </div>
          </div>
          <div tw="space-y-2">
            <span tw="label">Tags &amp; Labels</span>
            <TagsLabels tags={album?.tags} labels={album?.labels} />
          </div>
          <div tw="space-y-2">
            <span tw="label">Assets</span>
            <Uploads albumId={album?.id} collectionId={album?.collectionId} />
            <div tw="p-2 border border-grey text-grey-dark">
              filter / sort / group by ...
            </div>
            <div tw="grid grid-cols-3 gap-2">
              {assets.map((a) => (
                <AssetTile key={a.id} asset={a} />
              ))}
            </div>
          </div>
        </div>
        {assetId && (
          <Modal onClose={handleCloseAssetDetails} tw="w-full h-full">
            <AssetDetails
              album={album}
              id={assetId}
              onSelect={handleCloseAssetDetails}
              onClose={handleCloseAssetDetails}
            />
          </Modal>
        )}
      </AlbumContext.Provider>
    </CollectionContext.Provider>
    </ErrorBoundary>
  );
}

export default React.memo(AlbumView);
