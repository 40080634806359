import "twin.macro";
import React from "react";
import { UploadList } from "./UploadList";
import { useUploads } from "../../clients/apiHooks";

export interface UploadsProps {
  albumId: string;
  collectionId: string;
}

export function Uploads({
  albumId,
  collectionId,
}: UploadsProps): JSX.Element | null {
  const { atomFiles, handleFileSelect, handleUpload } = useUploads(
    albumId,
    collectionId
  );

  return (
    <div>
      <div tw="space-x-2">
        <form tw="inline-block">
          <label tw="btn inline-block">
            <input
              hidden
              name="upload"
              type="file"
              multiple
              onChange={handleFileSelect}
            />
            + Upload Asset
          </label>
        </form>
        <span>{atomFiles.length} Files selected</span>
        <button
          tw="inline-block"
          onClick={() => handleUpload(albumId, collectionId)}
        >
          Upload
        </button>
      </div>
      <UploadList current albumId={albumId} collectionId={collectionId} />
    </div>
  );
}

export default React.memo(Uploads);
