import tw, {styled} from "twin.macro";

import {useCallback, useEffect} from "react";

import { BaseProps } from "./BaseProps";

export const Backdrop = tw.div`fixed top-0 left-0 w-full h-full`;

export interface ModalProps {
  className?: string;
  children?: JSX.Element | JSX.Element[] | null;
  centered?: boolean | null;
  onClose?: () => void | null;
}

const ContentWrapper = styled.div((props: ModalProps) => [
  props.centered ? tw`inline-block absolute top-1/2 left-1/2 transform translate-x--1/2 translate-y--1/2 max-w-full overflow-auto` : tw`inline-block relative`]);

export const Modal = function (props: ModalProps): JSX.Element {
  const handleKeyPress = useCallback((event) => {
    event.key === "Escape" && props.onClose && props.onClose();
  }, [props]);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);

  return (
    <div tw="fixed top-0 left-0 w-full h-full overflow-hidden">
      <Backdrop onClick={props.onClose}/>
      <ContentWrapper centered={props.centered} className={props.className}>
        {props.children}
      </ContentWrapper>
      {props.onClose && (
        <div tw="fixed top-0 right-0 p-4 text-center" onClick={props.onClose}>
          <Icon t="close" tw="block text-5xl"/>
          <span tw="block text-xs mt--2 text-grey-dark">close</span>
        </div>)}
    </div>
  );
};

export interface IconProps {
  t: string;
}

export function Icon(props: BaseProps & IconProps): JSX.Element {
  return <i className={`mi-${props.t} ${props.className}`} onClick={props.onClick}/>
}
