import tw from "twin.macro";

import { useReducer, useCallback, useContext } from "react";

import { Tag, Label } from "../../clients/apiClient";
import ResourceInput from "../generic/ResourceInput";

import { TagItem, LabelItem, ItemGroup } from "./Items";
import { AlbumContext, CollectionContext, useAlbumHook } from "../../clients/apiHooks";

const Backdrop = tw.div`top-0 left-0 w-full h-full bg-white fixed`;

interface EditLabelProps {
  label?: string;
  onClose?: (evt: any) => void;
  onSubmit: (val: string) => void;
}
function EditLabel({ label, onClose, onSubmit }: EditLabelProps): JSX.Element {
  return (
    <div>
      <ResourceInput
        placeholder="label"
        initialValue={label}
        onSubmit={onSubmit}
        cta="ok"
      />
      {onClose && <button onClick={onClose}>close</button>}
    </div>
  );
}

type ComponentState = {
  mode: "view" | "edit-tag" | "edit-label" | "add-tag" | "add-label";
  isDirty: boolean;
  editTagIdx: number | null;
  editLabelIdx: number | null;
  tags?: Tag[];
  labels?: Label[];
};

type ComponentStateUpdate = {
  mode?: "view" | "edit-tag" | "edit-label" | "add-tag" | "add-label";
  isDirty?: boolean;
  editTagIdx?: number | null;
  editLabelIdx?: number | null;
  tags?: Tag[];
  labels?: Label[];
};

const initialState: ComponentState = {
  mode: "view",
  isDirty: false,
  editTagIdx: null,
  editLabelIdx: null,
};

function actionHandler(
  state: ComponentState,
  action: ComponentStateUpdate
): ComponentState {
  const newState = { ...state, ...action };
  if (state.isDirty) {
    newState.tags = state.tags;
    newState.labels = state.labels;
  }
  return newState;
}

function useComponentState(tags: Tag[], labels: Label[]) {
  const [state, dispatch] = useReducer(actionHandler, initialState);

  return {
    enableEdit: useCallback((evt) => {
      evt.stopPropagation();
      //dispatch({ mode: 'edit' });
    }, []),
    disableEdit: useCallback((evt) => {
      evt.stopPropagation();
      dispatch({ mode: "view" });
    }, []),
    selectTag: useCallback((index, tags) => {
      dispatch({
        mode: "edit-tag",
        editTagIdx: index,
        tags,
        editLabelIdx: null,
      });
    }, []),
    selectLabel: useCallback((index, labels) => {
      dispatch({
        mode: "edit-label",
        editLabelIdx: index,
        labels,
        editTagIdx: null,
      });
    }, []),
    updateLabel: useCallback((val) => {
      dispatch({ mode: "view", isDirty: true }); //, newVal: val }); // FIXME
    }, []),
    isSelectedTag: useCallback((idx) => state.editTagIdx === idx, [state]),
    isSelectedLabel: useCallback((idx) => state.editLabelIdx === idx, [state]),

    labelToEdit: state.editLabelIdx != null && labels[state.editLabelIdx],
    tagToEdit: state.editTagIdx != null && tags[state.editTagIdx],

    mode: state.mode,
    isDirty: state.isDirty,
    tag: state.editTagIdx,
    tags: (state.isDirty ? state.tags : tags) || [],
    label: state.editLabelIdx,
    labels: (state.isDirty ? state.labels : labels) || [],
  };
}

export interface TagsLabelsProps {
  tags?: Tag[];
  labels?: Label[];
}

function TagsLabels(props: TagsLabelsProps): JSX.Element {
  const state = useComponentState(props.tags || [], props.labels || []);
  const collectionId = useContext(CollectionContext);
  const albumId = useContext(AlbumContext);

  const { patchAlbum } = useAlbumHook(collectionId!, albumId!);

  const onKeyUp = (e: any) => {
    if (e.keyCode === 13) {
      const label = e.target.value;
      const new_vals = { labels: [...state.labels, label], tags: state.tags };
      patchAlbum(new_vals);
      e.target.value = "";
    }
  };

  return (
    <div style={{ content: "contain" }}>
      {state.mode !== "view" ? <Backdrop /> : null}
      <div
        className={(state.mode !== "view" && "relative") || undefined}
        onClick={state.enableEdit}
      >
        <ItemGroup>
          <div>
            {state.tags.map((t, idx) => (
              <TagItem
                key={t.key + t.value}
                tag={t}
                isHighlight={state.isSelectedTag(idx)}
                onClick={() => state.selectTag(idx, props.tags)}
              />
            ))}
            {state.labels.map((l, idx) => (
              <LabelItem
                key={l}
                isHighlight={state.isSelectedLabel(idx)}
                onClick={() => state.selectLabel(idx, props.labels)}
              >
                {l}
              </LabelItem>
            ))}
          </div>
        </ItemGroup>

        <ItemGroup>
          <li>
            <span tw="tag-key">+ Add Tag</span> <span tw="tag-val">Value</span>
          </li>
          <input
            tw="lbl bg-grey-lightest w-32"
            placeholder="+ Add Label"
            onKeyUp={onKeyUp}/>
        </ItemGroup>
        {state.labelToEdit && (
          <EditLabel
            key={state.label || 0}
            label={state.labelToEdit}
            onClose={state.disableEdit}
            onSubmit={state.updateLabel}
          />
        )}
        {/* state.mode === 'edit-tag' && <EditLabel label={(props.tags || [])[state.tag || 0].value} /> */}
        {state.mode === "view" && state.isDirty && (
          <div tw="text-center mt-12 space-x-8">
            <button onClick={state.disableEdit}>cancel</button>
            <button>reset</button>
            <button tw="action" onClick={state.disableEdit}>
              save
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default TagsLabels;
