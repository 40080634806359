import { useMatch, Link } from "react-router-dom";

import { CollectionBase } from "../../clients/apiClient";

export interface CollectionListLinkProps {
  collection: CollectionBase;
  className?: string;
}

function CollectionListLink({
  collection,
  className,
}: CollectionListLinkProps): JSX.Element {
  const target = `/collection/${collection.id}`;

  const routeMatch = useMatch({
    path: target,
  });
  return (
    <div className={className}>
      <Link to={target}>
        <div>
          {routeMatch && ">"} {collection.name}
        </div>
      </Link>
    </div>
  );
}

export default CollectionListLink;
