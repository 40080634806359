import { useMatch, Link } from "react-router-dom";

import { Album } from "../../clients/apiClient";

export interface AlbumListLinkProps {
  album: Album;
  className?: string;
}

function AlbumListLink({ album, className }: AlbumListLinkProps): JSX.Element {
  const target = `/collection/${album.collectionId}/a/${album.id}`;

  const routeMatch = useMatch({
    path: target,
  });
  return (
    <div className={className}>
      <Link to={target}>
        <div>
          {routeMatch && ">"} {album.name}
        </div>
      </Link>
    </div>
  );
}

export default AlbumListLink;
